import React from "react";
import { ClipboardListIcon } from "@heroicons/react/solid";

const Resume = () => {
  return (
    <div
      id="Resume"
      className="lg:w-1/2 mt-64 mb-4 text-justify mx-12 lg:mr-60 lg:ml-auto"
    >
      <div>
        <div className="table">
          <ClipboardListIcon className="h-5 w-5 mr-4 text-yellow_vs" />{" "}
          <code className="table-cell text-[#e6f1ff] text-3xl mt-5 whitespace-nowrap">
            Resume
          </code>
          <div className="table-cell border-b border-b-[#e6f1ff] border-opacity-25 w-full"></div>
        </div>
      </div>
      <div className="text-[#a2aabc] text-lg mt-5">
        <div className="flex flex-row">
          <div className="w-1/4">
            <code className="text-yellow_vs">Education</code>
          </div>
          <div className="w-3/4">
            <code className="text-blue_vs">Rungta college of Engineering and Technology</code>
            <br />
            <code className="italic text-sm text-lightblue_vs">
              Masters in Computer Applications
            </code>
            <br />
            <code className="text-xs text-brown_vs">
              • Nov 2020 - Oct 2022
            </code>
          </div>
        </div>
        <div className="flex flex-row pt-10">
          <div className="w-1/4">
            <code className="text-yellow_vs">Work</code>
          </div>
          <div className="w-3/4">
            <code className="text-blue_vs">Software Developer at CodeNicely</code>
            <br />
            <code className="italic text-sm text-lightblue_vs">
              Raipur - Chhattisgarh, India{" "}
            </code>
            <br />
            <code className="text-xs text-brown_vs">• Dec 2022 - Present</code>
            <br />
            <code className="text-sm">
              <br />• Successfully contributed to the completion of 5 diverse web development projects.
              <br />• Collaborated with cross-functional teams, including backend developers and designers, to ensure
              seamless integration and optimal user experience.
              <br />• Implemented performance optimizations with Nuxt js that resulted in a 20% reduction in page load
              times.
              <br />• Received positive feedback from clients for delivering projects on time and exceeding expectations.
              <br />• Successfully navigated challenges during the development of a Non-Banking Financial Company
              (NBFC) website, demonstrating problem-solving skills and adaptability. Overcame obstacles related
              to data security, regulatory compliance, and user authentication to deliver a robust and compliant
              frontend solution.
              <br />• Developed a custom trailing line library for a trading website, incorporating live price tracking, target
              price, stop loss, and entry price features. This innovative solution enhanced user experience and
              provided real-time data visualization for traders.
            </code>
          </div>
        </div>

        <div className="flex flex-row pt-10">
          <div className="w-1/4">
            {/* <code className="text-yellow_vs">Work</code> */}
          </div>
          <div className="w-3/4">
            <code className="text-blue_vs">Frontend Developer at Arosys</code>
            <br />
            <code className="italic text-sm text-lightblue_vs">
              Bhilai - Chhattisgarh, India{" "}
            </code>
            <br />
            <code className="text-xs text-brown_vs">• Jun 2022 - Dec 2022</code>
            <br />
            <code className="text-sm">
              <br />•  Excelled as a Frontend UI Developer specializing in Angular technologies, contributing to the devel-
              opment of dynamic and visually appealing user interfaces.
              <br />• Employed Angular framework, Angular Material, and Bootstrap to craft intuitive and responsive user
              interfaces for projects centered around a complex Lead Management System and Routing Solution.
              <br />• Worked on and successfully delivered multiple modules, each requiring a deep understanding of
              Angular technologies to address the unique challenges posed by complex lead management and
              routing functionalities.
              <br />• Participated in agile development methodologies, actively contributing to sprint planning, daily stand-
              ups, and retrospective meetings to enhance team collaboration and project efficiency.
            </code>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
